<template>
  <div style="height: 100%;background: #fff;">
    <div class="biao_ti">
      <van-icon @click="back" class="fanhui" name="arrow-left" />
      <img :src="chexin.carBrandLogo" class="che" alt />
      <!-- <span style="vertical-align: middle">
          
      </span>-->
    </div>
    <div style="padding-top:46px;position: relative;background: #fff;">
      <div class="names">{{chexin.carBrand}}-{{chexin.carModel}}</div>
      <div class="h_1">
        <div class="c_h1">{{chexin.carModelName}}</div>
        <div class="c_h2">
          <div class="yixuan">已选轮胎规格</div>
          <div class="xin">{{chexin.tires}}</div>
          <van-button class="biangen" round type="danger" @click="biangen">变更</van-button>
        </div>
      </div>
      <div class="c_naem">
        <img class="pei" src="../../assets/img/pie.png" alt />
        {{chexin.carBrand}}-{{chexin.carModel}} 其余轮胎规格
      </div>
      <div class="dd">
        <div
          class="cdd"
          v-for="(it,index) in list"
          :key="index"
          @click="tianjia(it)"
          :style="{color:(it == chexin.tires ?'#ef3085':'#666'),background:(it == chexin.tires?'#fef7f7':'#fff'),}"
        >
          <span>{{it}}</span>
          <span class="yuanpei" v-if="index == 0">原配</span>
        </div>
      </div>
      <div class="c_naem">
        <img class="pei" src="../../assets/img/pie.png" alt />如何知道轮胎规格？
      </div>
      <img src="../../assets/img/taiguige.png" class="tai" alt />
      <div class="bodt">
        <div class="zixuan left">
          <div class="zzgui">自选规格</div>
          <div class="ssdong">手动输入轮胎规格</div>
        </div>
        <div class="zixuan right">
          <div class="zzgui">咨询客服</div>
          <div class="ssdong">找客服帮忙选规格</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userModel from "../../api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      list: [],
      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    tianjia(item) {
      this.chexin.tires = item;
    },
    biangen() {
      var _this = this;
      const data = {
        id: this.chexin.id,
        tires: this.chexin.tires
      };
      userModel
        .biangentires(data)
        .then(e => {
          sessionStorage.setItem("chexin", JSON.stringify(_this.chexin));
          _this.$toast("变更成功！");
          setTimeout(() => {
            _this.$router.go(-1);
          }, 900);
        })
        .catch(() => loading.clear());
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      const data = {
        vinCode: this.chexin.carVin
      };
      userModel
        .vintires(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      this.onLoad();
    },

    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
// 标题
.biao_ti {
  height: 46px;
  background: #fff;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;
  color: #fff;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.fanhui {
  line-height: 46px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
  color: #000;
}
.che {
  width: 40px;
  height: 40px;
  margin-top: 2px;
}

.c_h1 {
  font-size: 13px;

  background: #f9f9f9;
  padding: 6px 10px;
  font-weight: bold;
}
.h_1 {
  overflow: hidden;
  margin: 8px 12px 0 12px;
  box-shadow: 1px 1px 10px #ebedf0;
}
.dd {
  width: 100%;
  font-size: 13px;
  color: #666;
  margin-top: 15px;
  min-height: 45px;
  overflow: hidden;
}
.pei {
  margin-right: 4px;
}
.tai {
  display: block;
  margin: auto;
  width: 95%;
  margin-top: 8px;
}
.cdd {
  position: relative;
  float: left;
  margin-left: 2%;
  height: 35px;
  line-height: 33px;
  width: 30%;
  font-size: 12px;
  text-align: center;
  border: 1px solid #f5f5f5;
  border-radius: 17px;
}
.cdd:nth-of-type(n + 4) {
  margin-top: 8px;
}
.yuanpei {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  transform: scale(0.7);
  color: #fff;
  background: #3cb371;
  line-height: 18px;
  border-radius: 4px;
  margin-top: 8px;
  display: inline-block;
}
.yixuan {
  display: inline-block;
  font-size: 11px;
  background: #f9f9f9;
  margin: auto;
  color: #666;
  padding: 0 8px;
  transform: scale(0.9);
  margin-top: 10px;
  line-height: 20px;
}
.xin {
  font-size: 22px;
  margin-top: 8px;
}
.biangen {
  width: 85%;
  height: 35px;
  margin-top: 20px;
}
.c_naem {
  margin-top: 12px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 12px;
}
.c_h2 {
  height: 140px;
  background: #fff;
  text-align: center;
}
.names {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #333;
  line-height: 30px;
}
.bodt {
  overflow: hidden;
  margin: 15px 12px;
  text-align: center;
}
.zixuan {
  font-size: 14px;
  height: 80px;
  width: 47%;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  background: #f1f1f1;
}
.zzgui {
  margin-top: 15px;
}
.ssdong {
  font-size: 12px;
  color: #666;
}
</style>